.footerWrapper {
  padding-top: 150px;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #3061F6;
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 130px 15px 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 90px;
  }
  @media (max-width: 767px) {
    padding: 80px 0 0;
  }

  &.noShape {
    padding-top: 100px;
    @media (min-width: 992px) and (max-width: 1200px) {
      padding-top: 90px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 80px;
    }
    @media (max-width: 767px) {
      padding-top: 70px;
    }
  }

  .widget {
    margin-bottom: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    &.hasPadding {
      @media (min-width: 1350px) {
        padding-left: 72px;
      }
    }
  }

  .widgetTitle {
    font-size: 20px;
    color: #404041;
    font-weight: 00;
    margin-bottom: 30px;
    font-family: 'Montserrat', sans-serif;
  }

  .footerContact {
    margin-bottom: 40px;

    > a {
      display: inline-block;
      margin-bottom: 30px;

      > img {
        width: 120px;
      }
    }

    p {
      font-size: 15px;
      color: #fff;
      letter-spacing: 0;
      font-weight: 500;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    ul {
      margin-top: 30px;

      li {
        display: block;
        font-size: 15px;
        color: #fff;
        letter-spacing: 0;
        font-weight: 500;
        margin-bottom: 9px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }

  .quickLinks {
    li {
      display: block;

      a {
        display: block;
        font-size: 15px;
        color: #fff;
        letter-spacing: 0;
        font-weight: 500;
        margin-bottom: 15px;
        @media (max-width: 767px) {
          font-size: 14px;
        }

        &:hover,
        &.active {
          color: #000;
        }
      }
    }
  }

  .ftNewsletter {
    position: relative;
    z-index: 1;

    input {
      height: 60px;
      border-radius: 5px 0 0 5px;
      border: 1px solid #ddd;
      padding-left: 15px;
      width: 100%;
      letter-spacing: 0;
      font-weight: 500;
      padding-right: 96px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding: 0 25px;
      font-weight: 600;
      border-radius: 0 5px 5px 0;
    }

  }

  .footerBgShape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.footerBottom {
  border-top: 1px solid #000000;
  padding-top: 30px;
  background-size: cover;
  @media (max-width: 767px) {
    padding-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}